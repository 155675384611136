import {lang} from "./config"

const langLib = {
  "review_data": ["复盘数据", "Recheck data"],
  "review_title": ["直播复盘", "Live replay"],
  "last_time_hint": ["较上个时刻", "Last time"],
  "script_title": ["直播带货脚本", "Live delivery script"],
  "student_answer": ["学生答案情况", "Student answers"],
  "correct_keyword": ["答对关键词", "Correct keyword"],
  "number_unit": ["个", ""],
  "verbal_trick": ["话术", "verbal trick"],
  "cumulative_viewership": ["累计观看人数", "Cumulative viewership"],
  "online_number": ["实时在线人数", "online number"],
  "order_num": ["订单数", "number of orders"],
  "sale": ["销售额", "sale"],
  "like_count": ["点赞数", "Like count"],
  "activity_start": ["活动按期开始", "The activity starts on schedule"],
  "approved": ["通过审核", "Approved"],
  "created_success": ["创建成功", "Created successfully"],
  "module_script": ["脚本话术:", "Script speaking"],
  "module_bag": ["脚本福袋:", "Script pocket"],
  "module_explain": ["商品讲解:", "Product Explanation"],
  "module_coupon": ["优惠券:", "coupon"],
  "module_QA": ["商品问答:", "Product Q&A"],
  "module_feature": ["产品卖点介绍:", "Product selling points"],
  "review_data_title": ["直播复盘数据", "Live replay data"],
  "back": ["返回", "back"],
  "peak_population": ["人数峰值", "Peak population"],
  "average_online": ["平均在线", "Average online"],
  "sales_volume": ["本场销量", "sales volume"],
  "review_data_sale": ["本场销售额", "sale"],
  "total_barrage": ["总弹幕", "Total barrage"],
  "like_this_session": ["本场点赞", "Like this session"],
  "this_barrage": ["本场弹幕", "This barrage"],
  "goods_hint": ["请选择商品", "Please select product"],
  "sex_distribution": ["性别分布", "sex distribution"],
  "age_distribution": ["年龄分布", "age distribution"],
  "geographical_distribution": ["地域分布", "geographical distribution"],
  "ranking": ["排名", "ranking"],
  "city": ["城市", "city"],
  "proportion": ["占比", "Proportion"],
  "comprehensive_data": ["综合数据", "Comprehensive data"],
  "interactive_data": ["互动数据", "interactive data"],
  "product_popularity": ["商品热度", "Product popularity"],
  "online_viewers": ["在线观看人数", "Online viewers"],
  "low": ["低", "low"],
  "high": ["高", "high"],
  "nanhai_zhudao": ["南海诸岛", "Nanhai Zhudao"],
}

export default lang(langLib)